import React, { useState } from "react";
import { map, filter, sort, find } from "ramda";
import useDataModel from "./useDataModel";
import "./styles.css";

// const relationships = [
//   {
//     type: "manyToOne",
//     source: "result.tester",
//     target: "tester.results",
//   },
//   {
//     type: "manyToOne",
//     source: "result.session",
//     target: "session.results",
//   },
//   {
//     type: "manyToOne",
//     source: "result.testCase",
//     target: "testCase.results",
//   },
//   {
//     type: "manyToMany",
//     source: "result.issues",
//     target: "issue.results",
//   },
//   {
//     type: "manyToOne",
//     source: "testCase.environment",
//     target: "environment.testCases",
//   },
//   {
//     type: "manyToOne",
//     source: "testCase.client",
//     target: "client.testCases",
//   },
// ];

const models = ({ load, loadMany, loadInverseMany, loadInverseAll }) => ({
  environment: (record) => ({
    ...record,
    testCases: () => loadInverseMany("testCase", "environment", record.id),
  }),
  client: (record) => ({
    ...record,
    testCases: () => loadInverseMany("testCase", "client", record.id),
  }),
  testCase: (record) => ({
    ...record,
    environment: () => load(record.environment),
    client: () => load(record.client),
    results: () => loadInverseMany("result", "testCase", record.id),
  }),
  tester: (record) => ({
    ...record,
    results: () => loadInverseMany("result", "tester", record.id),
  }),
  session: (record) => ({
    ...record,
    results: () => loadInverseMany("result", "session", record.id),
  }),
  issue: (record) => ({
    ...record,
    results: () => loadInverseAll("result", "issues", record.id),
  }),
  result: (record) => {
    const testCase = () => load(record.testCase);
    const tester = () => load(record.tester);
    const session = () => load(record.session);
    const issues = () => loadMany(record.issues);
    const name = () =>
      testCase().environment().shortName + "-" + testCase().seq;
    return {
      ...record,
      testCase,
      tester,
      session,
      issues,
      name,
    };
  },
});

export default function App({ records }) {
  const [selectedSessionId, setSelectedSessionId] = useState(undefined);
  const [selectedTesterId, setSelectedTesterId] = useState(undefined);
  const [selectedResultId, setSelectedResultId] = useState(undefined);
  const { loadAll, update } = useDataModel(models, records);

  const sortedSessions = sort(
    (a, b) => b.date.localeCompare(a.date),
    loadAll("session")
  );
  const sortedTesters = sort(
    (a, b) => a.name.localeCompare(b.name),
    loadAll("tester")
  );
  const filteredResults = filter(
    selectedSessionId === undefined
      ? () => true
      : ({ session }) => selectedSessionId === session().id,
    filter(
      selectedTesterId === undefined
        ? () => true
        : ({ tester }) => selectedTesterId === tester().id,
      loadAll("result")
    )
  );
  const selectedResult = find(
    ({ id }) => selectedResultId === id,
    filteredResults
  );
  return (
    <div className="App">
      <div>
        <select
          onChange={(event) => {
            setSelectedTesterId(event.target.value || undefined);
          }}
        >
          <option value="">All</option>
          {map(
            ({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ),
            sortedTesters
          )}
        </select>
        {sortedSessions.length > 0 ? (
          <ol>
            {sortedSessions.map((session) => (
              <li key={session.id}>
                <div
                  style={{
                    background:
                      selectedSessionId && selectedSessionId === session.id
                        ? "#eee"
                        : "transparent",
                  }}
                  onClick={() => {
                    setSelectedSessionId(session.id);
                  }}
                >
                  {session.date}{" "}
                  {map(
                    (result) => result.testCase().environment().shortName,
                    session.results()
                  ).join("")}{" "}
                  {map(
                    (result) => result.testCase().client().name,
                    session.results()
                  ).join("")}
                </div>
                {session.id === selectedSessionId ? (
                  <ol>
                    {filteredResults.map(({ id, name, status }) => (
                      <li
                        key={id}
                        style={{
                          background:
                            selectedResult && selectedResult.id === id
                              ? "#eee"
                              : "transparent",
                        }}
                        onClick={() => {
                          setSelectedResultId(id);
                        }}
                      >
                        {name()} {status}
                      </li>
                    ))}
                  </ol>
                ) : null}
              </li>
            ))}
          </ol>
        ) : (
          <em>…</em>
        )}
      </div>
      <div>
        {selectedResult ? (
          <div>
            <p>On {selectedResult.testCase().client().name}:</p>
            <pre>{selectedResult.testCase().instructions}</pre>
            <p>Status:</p>
            <ol>
              <li>
                <label>
                  <input
                    type="radio"
                    name="result"
                    checked={!selectedResult.status}
                    onChange={() => {
                      update(selectedResult.id, (current) => ({
                        ...current,
                        status: undefined,
                      }));
                    }}
                  />{" "}
                  Pending
                </label>
              </li>
              <li>
                <label>
                  <input
                    type="radio"
                    name="result"
                    checked={selectedResult.status === "passed"}
                    onChange={() => {
                      update(selectedResult.id, (current) => ({
                        ...current,
                        status: "passed",
                      }));
                    }}
                  />{" "}
                  Pass
                </label>
              </li>
              <li>
                <label>
                  <input
                    type="radio"
                    name="result"
                    checked={selectedResult.status === "failed"}
                    onChange={() => {
                      update(selectedResult.id, (current) => ({
                        ...current,
                        status: "failed",
                      }));
                    }}
                  />{" "}
                  Fail
                </label>
              </li>
            </ol>
          </div>
        ) : (
          <em>…</em>
        )}
      </div>
    </div>
  );
}
