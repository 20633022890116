import { useState } from "react";

const useDataModel = (models, defaultRecords) => {
  const [records, setRecords] = useState(defaultRecords);
  const result = {
    insert: (record) => {
      setRecords((current) => [...current, record]);
    },
    update: (id, updater) => {
      setRecords((current) =>
        current.map((record) => (record.id === id ? updater(record) : record))
      );
    },
    destroy: (id) => {
      setRecords((current) => current.filter((record) => record.id !== id));
    },
    load: (id) => {
      const record = records.find((record) => record.id === id);
      return record ? models(result)[record.type](record) : null;
    },
    loadMany: (ids) =>
      records
        .filter((record) => ids.includes(record.id))
        .map((record) => models(result)[record.type](record)),
    loadInverse: (type, field, id) => {
      const record = records.find(
        (record) => record.type === type && record[field] === id
      );
      return record ? models(result)[record.type](record) : null;
    },
    loadInverseMany: (type, field, id) =>
      records
        .filter((record) => record.type === type && record[field] === id)
        .map((record) => models(result)[record.type](record)),
    loadInverseAll: (type, field, id) =>
      records
        .filter((record) => record.type === type && record[field].includes(id))
        .map((record) => models(result)[record.type](record)),
    loadAll: (type) =>
      records
        .filter((record) => record.type === type)
        .map((record) => models(result)[record.type](record)),
  };
  return result;
};

export default useDataModel;
