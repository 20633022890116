import React from "react";
import ReactDOM from "react-dom";
import dedent from "dedent";

import App from "./App";

const records = [
  {
    type: "environment",
    id: "724ab736-8c88-4fbc-970d-81561b9df287",
    name: "Production",
    shortName: "PROD",
  },
  {
    type: "environment",
    id: "27921fe1-24df-4a2c-ab00-6f2519916abd",
    name: "Test",
    shortName: "TEST",
  },
  {
    type: "client",
    id: "14b2e6d4-0254-4ca9-b042-c95895727dbb",
    name: "iOS/Safari",
  },
  {
    type: "client",
    id: "0de7c75f-99f6-4191-909b-61b7458e2875",
    name: "macOS/Safari",
  },
  {
    type: "testCase",
    id: "046ed02f-48bd-4720-af70-222d7d8c20eb",
    environment: "724ab736-8c88-4fbc-970d-81561b9df287",
    client: "14b2e6d4-0254-4ca9-b042-c95895727dbb",
    seq: 1,
    instructions: dedent`
        1. Visit https://example.com/
        2. Click the button labelled "Click Me"
      `,
    expectedResult: null,
  },
  {
    type: "tester",
    id: "ed2c7bac-8515-447f-ba88-2dea4e914423",
    name: "Jane Doe",
  },
  {
    type: "tester",
    id: "066e30e7-d8f9-4fc2-ba18-12a2429446ce",
    name: "Sally Smith",
  },
  {
    type: "session",
    id: "13121129-98b2-4215-a859-5f98a05a1918",
    seq: 1,
    date: "2021-06-01",
  },
  {
    type: "session",
    id: "90310cde-de69-4c99-bdae-f253f234c44d",
    seq: 2,
    date: "2021-06-02",
  },
  {
    type: "issue",
    id: "8e9081be-7e33-4119-83fa-f46c9b5d9231",
    description: "The button was missing.",
    severity: "high",
    status: "unresolved",
  },
  {
    type: "result",
    id: "2662d188-3966-46f4-ab43-d99a9e2919e9",
    testCase: "046ed02f-48bd-4720-af70-222d7d8c20eb",
    tester: "ed2c7bac-8515-447f-ba88-2dea4e914423",
    session: "13121129-98b2-4215-a859-5f98a05a1918",
    issues: ["8e9081be-7e33-4119-83fa-f46c9b5d9231"],
    status: "failed",
  },
  {
    type: "result",
    id: "94827659-dc7a-418d-8c8e-5d279dd2ccc7",
    testCase: "046ed02f-48bd-4720-af70-222d7d8c20eb",
    tester: "066e30e7-d8f9-4fc2-ba18-12a2429446ce",
    session: "90310cde-de69-4c99-bdae-f253f234c44d",
    issues: [],
    status: "passed",
  },
];

ReactDOM.render(
  <React.StrictMode>
    <App records={records} />
  </React.StrictMode>,
  document.getElementById("root")
);
